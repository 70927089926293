import { render, staticRenderFns } from "./overlayParallax.vue?vue&type=template&id=764335d6&scoped=true&"
var script = {}
import style0 from "./overlayParallax.vue?vue&type=style&index=0&id=764335d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "764335d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VParallax } from 'vuetify/lib/components/VParallax';
installComponents(component, {VBtn,VContainer,VOverlay,VParallax})
